import { useEffect} from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';



import 'holderjs/holder';

import "animate.css/animate.css"
import ScrollAnimation from 'react-animate-on-scroll';


import bathroom_1 from '../Assets/Images/bathroom_1.jpg';
import bathroom_2 from '../Assets/Images/bathroom_2.jpg';

import page_header from '../Assets/Images/bathroom_header.jpg';

const Main = () => {

  
 useEffect(() => {
     document.title = "Woodstock Bathrooms";
   }, []);

  return (
    <>
    <section className='page_header' style={{backgroundImage: `url(${ page_header })`}}>
        <h1 className='myBlur'>Bespoke Bathrooms</h1>
    </section>

    <section py-3>
      <Container>
        <Row>
         
          <Col md={8}>
            <h2 className='page_title merriweather-regular'>Bespoke Bathrooms</h2> 
            <p>When it comes to bathroom fitting in Sussex, Woodstock Constructions stands out as the premier choice for homeowners seeking quality and expertise. With years of experience, our team of skilled fitters specializes in transforming bathrooms into luxurious, functional spaces tailored to your needs. We understand that a bathroom is more than just a utility space—it's a personal retreat where comfort and style meet. That's why we take a meticulous approach to every project, ensuring that each bathroom we fit reflects the highest standards of craftsmanship and quality.</p>
            <p>Our fitters are the backbone of Woodstock Constructions, bringing a wealth of knowledge and precision to every bathroom fitting job in Sussex. We pride ourselves on our ability to handle all aspects of the fitting process, from plumbing and tiling to electrical work and custom installations. Our fitters work closely with you to understand your vision and execute it with attention to detail, ensuring that every fixture and finish is perfectly aligned with your expectations. We don’t just fit bathrooms; we create spaces that elevate the quality of your daily routine.</p>
         
          </Col>

          <Col md={4}>
            <ScrollAnimation 
              className="animate__animated"
              animateIn="animate__fadeInRight"
              duration={1}
            >
                <Image src={ bathroom_1 } fluid></Image>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col md={4} xs={{ order: 2 }}>
            <ScrollAnimation 
              className="animate__animated"
              animateIn="animate__fadeInLeft"
              duration={1}
            >
                <Image src={ bathroom_2 }  fluid></Image>
            </ScrollAnimation>  
             
          </Col>

          <Col md={8}  xs={{ order: 1 }}>
          <p>Quality is at the heart of everything we do at Woodstock Constructions. We source only the finest materials and work with top suppliers to ensure that your bathroom is built to last. Our commitment to quality extends to our customer service as well—our fitters are not only skilled but also professional and respectful of your home. We work efficiently and cleanly, minimizing disruption to your daily life while delivering a finished product that is both beautiful and durable. With Woodstock Constructions, you can be confident that your bathroom fitting will be of the highest quality, down to the last detail.</p>
          <p>Choosing Woodstock Constructions for your bathroom fitting in Sussex means choosing quality, expertise, and a team of dedicated fitters who are passionate about their craft. We understand the importance of getting every aspect of your bathroom just right, from the initial design to the final installation. That’s why we go the extra mile to ensure that every project we undertake meets our exacting standards and exceeds your expectations. Trust Woodstock Constructions to provide a bathroom fitting service that is second to none, delivering a space where quality and comfort come together seamlessly.</p>
            <Button> Contact Us </Button>
          </Col>


        </Row>
        </Container>
    </section>

    
        
    </>
    );
  }
  
  export default Main;