
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AppContext from '../AppContext';

// import Row from 'react-bootstrap/Row';
// import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';


function NavBar() {
  return(
    <>

    <Container className=' mb-3 border-bottom navbar_container' >
 
      <Navbar expand="md" fixed='top' className=" p-0 nav nav-underline justify-content-between " id="navbar">
        <Container className="">
            <Navbar.Brand href="/">Woodstock Kitchens <span className='mob_only gold'>{AppContext.phone_number}</span></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
            <Navbar.Collapse className="justify-content-end">
            <Nav className='mb-auto' >
                <Nav.Link  activeClassName='active' className='nav-item nav-link ' href="/"  >Home</Nav.Link>
                <Nav.Link  activeClassName='active' className='nav-item nav-link ' href="/kitchens" >Kitchens</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/bathrooms" >Bathrooms</Nav.Link>
                <Nav.Link className='nav-item nav-link ' href="/photos" >Our Work</Nav.Link>

                {/* <Nav.Link className='nav-item nav-link ' href="/" >Your House</Nav.Link> */}
                {/* <Nav.Link className='nav-item nav-link ' href="/" >Abous Us</Nav.Link> */}
                <div className='no_mob'><Nav.Link className='nav-item nav-link ' href="/testimonials" >Testimonials</Nav.Link></div>

                <Nav.Link href="/contact" >Contact</Nav.Link>
      
              </Nav>
              <Nav>
            <Nav.Item href="" className="gold">{AppContext.phone_number}</Nav.Item>
           
          </Nav>
            </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
    <section></section>
    </>
  );
}


export default NavBar;