import { useEffect } from "react";

import { Row, Col, Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';



import page_header from '../../Assets/Images/contact_header.jpg';



const project_photos = Array(17).fill().map((item, i) => (
   
        <Col xs={6} md={4}>
            <Image key={i} src={require(`../../Assets/Images/Gallery/woodstock_kitchens_${i + 1}.jpg`)} fluid className="project_photo"  />
        </Col>
  
));

const shuffledArray = project_photos.sort((a, b) => 0.5 - Math.random());



const Main = () => {
  
  
 useEffect(() => {
     document.title = "Woodstock Kitchens - Latest Phtos";
   }, []);

  return (
    <>
    <section className='page_header' style={{backgroundImage: `url(${ page_header })`}}>
        <h1 className='myBlur'>Our Projects</h1>
    </section>
    <section>
      <Container>
        <Row>
            <Col>
                <p>Check out the stunning photos of our latest projects in kitchens and bathrooms! At Woodstock Kitchens, we take pride in transforming ordinary spaces into extraordinary ones. Our recent kitchen renovations showcase modern designs with high-quality finishes, while our bathroom projects highlight luxurious fixtures and smart layouts. Browse our gallery to see how we can bring your vision to life with our expert craftsmanship and attention to detail.</p>
            </Col>
        </Row>
        <Row>
            { shuffledArray }
        </Row>
      </Container>
      </section>
      </>
  );
}

export default Main;