import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import backgroundImage from '../../Assets/Images/testimonials.jpg';

const Main = () => {

  
    useEffect(() => {
        document.title = "Woodstock Kitchens";
    }, []);

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        adaptiveHeight: true,
        arrows: false
      };

    return (
    <>
    <section  className='py-0' style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${ backgroundImage })`}} >
    <Container >
    <Row className='testimonial-slider'>
        <Col>

        
        
    <Slider {...settings}>
      <div className='dark_blur testimonial-caption'>
        <p>David and his team were personally recommended to me by a friend who has used them as their trusted builder for many years. </p>
        
        <p className='no_mob'>David was immediately very personable when he came to look at the job and was able to give me very informative advice about the pros and cons  and the impact on price of the ideas I had.</p>
        <p className='no_mob'>I also found his guidance as to what specifications would be more attractive and practical without imposing his own views. Without his advice I the room would not look as good as it does now.</p>
        <p className='no_mob'>I would be more than happy to be contacted if anyone would like any further details. </p>
        </div>
        <p><span>Andy</span></p>
    
      
    </Slider>
    </Col>
    </Row>
    </Container>
 
          

    </section>

    </>
    );
}

export default Main;