import { useRef, useEffect } from "react";

import { Row, Col, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import page_header from '../Assets/Images/contact_header.jpg';

import Form from 'react-bootstrap/Form';

import emailjs from "@emailjs/browser";


const Main = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_i7s27x9', 'template_uacz3i6', form.current, {
        publicKey: 'qWR8FoThTG_AT819x',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

//   useEffect(() => emailjs.init("qWR8FoThTG_AT819x"), []);

// const emailRef = useRef<HTMLInputElement>();
// const nameRef = useRef<HTMLInputElement>();
// const phoneRef = useRef<HTMLInputElement>();
// const messageRef = useRef<HTMLTextAreaElement>();
// const [loading, setLoading] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const serviceId = "service_i7s27x9";
//     const templateId = "template_uacz3i6";
//     try {
//       setLoading(true);
//       await emailjs.send(serviceId, templateId, {
//        name: nameRef.current.value,
//         email: emailRef.current.value,
//         phone: phoneRef.current.value,
//         message: messageRef.current.value
//       });
//       alert("email successfully sent check inbox");
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   };

  
 useEffect(() => {
     document.title = "Contact Woodstock Kitchens";
   }, []);

  return (
    <>
    <section className='page_header' style={{backgroundImage: `url(${ page_header })`}}>
        <h1 className='myBlur'>Contact Us</h1>
    </section>
    <section>
      <Container>
        <Row>
          <Col xs={12} md={6} className="p-4 p-md-5 rounded text-body-emphasis background-blend ">
                    <h1 class=" ">Contact Us</h1>
                    <p class="lead my-3">We’re here to help! Reach out with any questions or feedback, and we'll get back to you as soon as possible.</p>          </Col>
            <Col md={6}>
            <Form ref={form} onSubmit={sendEmail}>

            <Form.Group className="mb-3" controlId="nameRef">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Name" name="name" />
            </Form.Group>

              <Form.Group className="mb-3" controlId="emailRef">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="email" />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

            <Form.Group className="mb-3" controlId="phoneRef">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="text" name="phone" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="messageRef">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" />
            </Form.Group>


            <input type="submit" value="Send" className="btn btn-primary w-100 py-2" />


            </Form>



            </Col>
        </Row>
        </Container>
    </section>

    
        
    </>
    );
  }
  
  export default Main;