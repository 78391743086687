import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Footer() {

  return (
        <footer className='p-3'>
            <Container>
                <Row >
                    
                    <Col xs={12} md={3} className="">
                    Woodstock Kitchens
                    </Col>
                    <Col xs={12} md={3} className="footer_text">
                        &copy; {(new Date().getFullYear())}
                    </Col>
                    <Col xs={12} md={6} className="">
                        
                        
                        
                    </Col>
                
                </Row>
            </Container>
        </footer>

  );
}

export default Footer;