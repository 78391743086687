import { useEffect} from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import AppContext from '../../AppContext';

// import 'holderjs/holder';

import "animate.css/animate.css"
import ScrollAnimation from 'react-animate-on-scroll';

import home_image from '../../Assets/Images/lorem_image.jpg';
import home_image_1 from '../../Assets/Images/home_image_1.jpg';
import home_image_2 from '../../Assets/Images/home_image_2.jpg';
import home_image_3 from '../../Assets/Images/home_image_3.jpg';
import home_kitchen_1 from '../../Assets/Images/home_kitchen_1.jpg';
import home_kitchen_2 from '../../Assets/Images/home_kitchen_2.jpg';
import home_kitchen_3 from '../../Assets/Images/kitchen_1.jpg';

const Main = () => {

  
 useEffect(() => {
     document.title = "Woodstock Kitchens";
   }, []);

  return (
    <>
    <section className='py-1'>

    {/* <Image src={nice_kitchen} className='header_image'></Image> */}

    </section>
    <section>
      <Container>
        <Row>
          <Col className='no_mob'>
            <Image src={home_image} fluid ></Image>
          </Col>
          <Col>
            <h2 className='page_title '>Bespoke Kitchens</h2>
            <Image src={home_image} fluid  className='mob_only'></Image>
            <p>Welcome to Woodstock Kitchens, where craftsmanship meets innovation. With over 30 years of experience in the industry, we specialize in creating beautiful, functional kitchens that are tailored to your lifestyle. Our expert team is passionate about transforming your vision into reality, whether you're looking for a modern, sleek design or a warm, traditional space. At Woodstock Kitchens, we believe that the heart of every home is its kitchen, and we are dedicated to building spaces where memories are made and meals are shared.</p>
            {/* <p>Welcome to Woodstock Kitchens, where exceptional design meets timeless craftsmanship. We specialize in creating bespoke kitchens that reflect your unique style and needs. Whether you're dreaming of a modern, sleek layout or a warm, traditional space, our expert team is dedicated to bringing your vision to life. Using the finest materials and innovative techniques, we craft kitchens that are not only beautiful but also built to last.</p> */}
            <p>At Woodstock Kitchens, we understand that the kitchen is the heart of your home, a place where memories are made. That's why we take pride in delivering personalized service from start to finish, ensuring every detail is perfect. Our commitment to quality and customer satisfaction is unmatched, making us the trusted choice for kitchen design and renovation. Explore our portfolio and discover how we can transform your kitchen into a space that you'll love for years to come.</p>
            
            <Button> Contact Us </Button> 
          </Col>
            
        </Row>
        </Container>
    </section>

    <section className='py-5'>
      <Container>
        <Row>
        
          <Col md={6} className=''>
          <ScrollAnimation 
              className="animate__animated"
              animateIn="animate__fadeInLeft"
              duration={1}
              >
            <h2 className='page_title merriweather-regular'>Quality Craftmanship</h2>
            <p>Our commitment to quality and customer satisfaction has been the cornerstone of our success for three decades. We pride ourselves on using only the finest materials and the latest design trends to create kitchens that are not only aesthetically pleasing but also durable and practical. From the initial consultation to the final installation, we work closely with you every step of the way to ensure your complete satisfaction. Discover the Woodstock Kitchens difference today, and let us help you design the kitchen of your dreams.</p>
            </ScrollAnimation>
          </Col>
          
          <Col md={6} className='no_mob'>
            <Row>
              <ScrollAnimation 
                className="animate__animated col-md-4"
                animateIn="animate__fadeInRight"
                duration={1}
              >
                <Image src={home_image_1} fluid />  
              </ScrollAnimation>

              <ScrollAnimation 
                className="animate__animated col-md-4"
                animateIn="animate__fadeInRight"
                duration={1}
                delay={500}
              >
                <Image src={home_image_2} fluid />  
              </ScrollAnimation>

              <ScrollAnimation 
                className="animate__animated col-md-4"
                animateIn="animate__fadeInRight"
                duration={1}
                delay={1000}
              >
                <Image src={ home_image_3 } fluid />  
              </ScrollAnimation>              

            </Row>
          </Col>
          
              
        
        </Row>
      </Container>
    </section>

      <section className='testimonial'>
        <Row className='justify-content-md-center'>
          <Col xs md={8}>
            
              <p>{ AppContext.by_line }</p>
            
          </Col>
        </Row>
      </section>

      <section>
      <Container>
        <Row>
          <Col >
            <h2 className='page_title merriweather-regular'>Our Latest Projects</h2>
          </Col>
        </Row>
        <Row>       
          <Col>
            <Image src={ home_kitchen_1 } fluid className='square_image'/>
          </Col>
          <Col>
            <Image src={ home_kitchen_2 } fluid className='square_image' />
          </Col>
          <Col>
          <Image src={ home_kitchen_3 } fluid className='square_image' />
          </Col>
        </Row>
      </Container>
    </section>
        
      </>
  );
}

export default Main;