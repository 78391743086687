import { useEffect} from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';



import 'holderjs/holder';

import "animate.css/animate.css"
import ScrollAnimation from 'react-animate-on-scroll';


import bespoke_kitchen from '../Assets/Images/bespoke_kitchens.jpg';
import bespoke_kitchen_2 from '../Assets/Images/bespoke_kitchens_2.jpg';

import nice_kitchen from '../Assets/Images/kitchen_1.jpg';
const Main = () => {

  
 useEffect(() => {
     document.title = "Woodstock Kitchens";
   }, []);

  return (
    <>
    <section className='page_header' style={{backgroundImage: `url(${ nice_kitchen })`}}>
        <h1 className='myBlur'>Bespoke Kitchens</h1>
    </section>

    <section py-3>
      <Container>
        <Row>
         
          <Col>
            <h2 className='page_title merriweather-regular'>Bespoke Kitchens</h2> 
            <p>At Woodstock Kitchens, we specialize in crafting bespoke kitchens that are as unique as the individuals who use them. We believe that a kitchen should be a reflection of your personal style, needs, and aspirations. With over 30 years of experience in the industry, we have honed our ability to design and build kitchens that are completely tailored to each client. Our bespoke approach means that every detail, from the layout to the materials, is carefully considered to create a space that is perfectly suited to your lifestyle. Whether you desire a contemporary, minimalist kitchen or a warm, rustic space, we bring your vision to life with precision and creativity.</p>
            <p>What sets Woodstock Kitchens apart is our dedication to creating kitchens that are not just custom-made, but truly unique. We understand that no two homes are the same, and neither are the people who live in them. That's why we take the time to understand your specific needs, preferences, and daily routines. Our design process is collaborative, ensuring that your input is integral at every stage. We don’t believe in a one-size-fits-all approach—instead, we focus on crafting a kitchen that is uniquely yours, combining innovative design with exceptional functionality.</p>
          </Col>

          <Col xs={12}>
            <ScrollAnimation 
              className="animate__animated"
              animateIn="animate__fadeInRight"
              duration={1}
            >
                <Image src={ bespoke_kitchen } fluid></Image>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col xs={{span: 12, order: 2}}>
            <ScrollAnimation 
              className="animate__animated"
              animateIn="animate__fadeInLeft"
              duration={1}
            >
                <Image src={ bespoke_kitchen_2 } fluid></Image>
            </ScrollAnimation>  
             
          </Col>

          <Col>
            <p>Our commitment to uniqueness extends beyond design; it is also reflected in the quality of materials and craftsmanship that go into every Woodstock Kitchens project. We source the finest materials and work with skilled artisans to ensure that your kitchen is built to the highest standards. From custom cabinetry to bespoke countertops, every element is meticulously crafted to fit your space and style. Our attention to detail and dedication to quality means that your bespoke kitchen will not only be beautiful but also durable and long-lasting, providing you with a space that you will love for years to come.</p>
            <p>Choosing Woodstock Kitchens means choosing a partner who is as invested in your kitchen as you are. We take pride in our ability to create kitchens that are truly unique, offering a level of personalization and quality that is unmatched in the industry. From the first consultation to the final installation, we are committed to delivering a kitchen that exceeds your expectations. Trust Woodstock Kitchens to create a bespoke kitchen that is uniquely yours, where every detail is designed with your individuality in mind.</p>
            <Button> Contact Us </Button>
          </Col>


        </Row>
        </Container>
    </section>

    
        
    </>
    );
  }
  
  export default Main;